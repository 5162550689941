import React, {
  useState,
  useEffect,
  useContext,
} from 'react';

import {
  bearExAbi2,
  bearExAddress2,
  bearExBoxesAbi,
  bearExBoxesAddress,
} from '../utils/constants';

import { ModalContext } from './ModalContext';

import { ethers } from "ethers";

export const NFTContractContext = React.createContext();

const { ethereum } = window;



export const shortenAddress = (address) => `${address.slice(0, 4)}...${address.slice(address.length - 4)}`;

const rarityValue = {
  'ordinary': 1,
  'common': 2,
  'blended': 3,
  'strange': 4,
  'rare': 5,
  'special': 6,
  'epic': 7,
  'legendary': 8,
  'infinity': 9,
}

function addressEqual(a, b) {
  return a.toLowerCase() === b.toLowerCase();
}

const createEthereumContract = () => {
  const provider = new ethers.providers.Web3Provider(ethereum);
  const signer = provider.getSigner();
  const bearExContract2 = new ethers.Contract(bearExAddress2, bearExAbi2, signer);
  const bearExBoxContract = new ethers.Contract(bearExBoxesAddress, bearExBoxesAbi, signer);

  return {
    bearExContract2,
    bearExBoxContract,
  };
};

export const NFTContractProvider = ({children}) => {
  const [ currentAccount, setCurrentAccount ] = useState(null);
  const [ shortCurrentAccount, setShortCurrentAccount ] = useState(null);
  const [ contractListened, setContractListened ] = useState(null);
  const [ contractListenedStatus, setContractListenedStatus ] = useState(false);

  const [ isMetamaskInstall, setIsMetamaskInstall ] = useState(true);
  
  const [ boxes, setBoxes ] = useState([]);
  const [ NFTs, setNFTs ] = useState([]);
  const [ accountImgValue, setAccountImgValue ] = useState(0);
  const [ newNFTCard, setNewNFTCard ] = useState(null);
  const  [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  const width = dimensions?.width;

  const {
    toggleModal,
    openLoadingModal,
    closeLoadingModal,
    loadingModalIsOpen,
    modalIsOpen,
    toggleNFTErrorModal,
  } = useContext(ModalContext);

  const addNFT = (card) => {
    setNFTs(currentState => {
      return [...currentState, card]
    })
  };

  const NFTCardSideToggle = (id) => {
    setNFTs(currentState => {
      return currentState.map(NFTCard => {
        if (NFTCard.id === id) {
          return {
            ...NFTCard,
            front: !NFTCard.front,
          }
        }

        return {
          ...NFTCard,
        }
      })
    })
  }
  
  const checkIfWalletIsConnected = async () => {
    try {
      if (!ethereum) {
        // connectMetamaskToggle();
        return;
      };
  
      setIsMetamaskInstall(true);
  
      const accounts = await ethereum.request({ method: 'eth_accounts' });
  
      if (accounts.length) {
        setCurrentAccount(accounts[0]);
        setShortCurrentAccount(shortenAddress(accounts[0]));
      } 
    } catch (error) {
      console.log(error);
    }
  }

  const connectWallet = async () => {
    try {
      if (!ethereum) {
        setIsMetamaskInstall(false);
        // connectMetamaskToggle();
        return;
      };

      const accounts = await ethereum.request({ method: 'eth_requestAccounts' });

      setCurrentAccount(accounts[0]);
      setShortCurrentAccount(shortenAddress(accounts[0]));
    } catch (error) {
      console.log(error);

      throw new Error("No ethereum object.");
    }
  }

  const getBoxes = async () => {
    try {
      if (currentAccount === null) return;
      const {
        bearExBoxContract,
      } = createEthereumContract();

      const balanceOfId1 = await bearExBoxContract.balanceOf(currentAccount, 1);
      const balanceOfId2 = await bearExBoxContract.balanceOf(currentAccount, 2);
      const balanceOfId3 = await bearExBoxContract.balanceOf(currentAccount, 3);

      const balanceOfId1Number = balanceOfId1.toNumber();
      const balanceOfId2Number = balanceOfId2.toNumber();
      const balanceOfId3Number = balanceOfId3.toNumber();

      const balanceOf = balanceOfId1Number + balanceOfId2Number + balanceOfId3Number;

      for (let i = 1; i <= balanceOf; i++) {
        setBoxes(currentState => {
          return [
            ...currentState,
            {
              id: i,
              boxId: i <= balanceOfId1Number ? 1 : i <= balanceOfId1Number + balanceOfId2Number ? 2 : 3,
              buttonTitle: 'open',
            }
          ]
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  const redeemedEvent = (sender, tokensIds) => {
    console.log('Redeemed start');
    closeLoadingModal();
    const newNFT = {
      id: tokensIds.toNumber(),
    }

    console.log({
      sender,
      newNFT,
      tokensIds,
    })

    // setAccountImgValue(currentState => {
    //   const newRarityImgValue = rarityValue[raritynames];
    //   console.log({
    //     newRarityImgValue,
    //   })
    //   if (newRarityImgValue > currentState) {
    //     return newRarityImgValue
    //   }

    //   return currentState;
    // })

    toggleModal();
    
    setNewNFTCard(newNFT)
    addNFT(newNFT);
    setContractListenedStatus(currentState => !currentState);
    console.log('Redeemed end');
  }

  const getNFT = async (id) => {
    try {
      if (currentAccount === null) return;
      const redeemed = await contractListened.RedeemNFT(id);
      console.log({
        listenedCount : contractListened.listenerCount("Redeemed"),
      });
      console.log({
        redeemed,
      });

      if (redeemed) {
        openLoadingModal();

        redeemed.wait()
          .then(res => {
            console.log(res);
            setBoxes(currentState => {
              return currentState.filter(box => box.id !== id);
            })
            // setBoxes(currentState => {
            //   return currentState.map(box => {
            //     return box.id === id 
            //       ? {
            //         id: box.boxId,
            //         boxId: box.boxId,
            //         buttonTitle: 'buy',
            //       } 
            //       : {
            //         ...box,
            //       }
            //   });
            // });
            // setContractListened(redeemed);
            contractListened.once("Redeemed", redeemedEvent);
            console.log({
              listenedCount : contractListened.listenerCount( "Redeemed"),
            });
          })
          .catch(error => {
            console.log(error);
            closeLoadingModal();
            toggleNFTErrorModal();
          });
      }
    } catch (error) {
      if (loadingModalIsOpen) {
        closeLoadingModal();
      };

      if (modalIsOpen) {
        toggleModal();
      };
      console.log(error);
    }
  }

  const checkRedeemedIds = async (start, finish) => {
    for (let i = start; i <= finish; i++) {
      const owner = await contractListened.ownerOf(i);
  
      if (addressEqual(owner, currentAccount)) {
        const NFTCardData = {
         id: i,
        }
        console.log(NFTCardData)
        addNFT(NFTCardData);
      }
  
    }
  }

  const checkNFTs = async () => {
    try {
      if (currentAccount === null) return;

      const tokenIds = await contractListened.tokenIds();

      console.log(tokenIds.toNumber());

      for (let i = 1; i <= tokenIds.toNumber(); i++) {

        const redeemedTokens = await contractListened.redeemedTokens(i);
        if (redeemedTokens) {
          const owner = await contractListened.ownerOf(i);
          // console.log({
          //   owner,
          //   currentAccount,
          //   equal: addressEqual(owner, currentAccount),
          // })
          if (addressEqual(owner, currentAccount)) {
            const NFTCardData = {
             id: i,
            }
            console.log(NFTCardData)
            
            addNFT(NFTCardData);
          }
        }
        // console.log(owner)
        // if (addressEqual(owner, currentAccount)) {
        //   const attributes = await contractListened.attributes(i);
        //   const {
        //     energy,
        //     level,
        //     lucky,
        //     name,
        //     rarity,
        //   } = attributes;

        //   setAccountImgValue(currentState => {
        //     const newRarityImgValue = rarityValue[rarity];

        //     if (newRarityImgValue > currentState) {
        //       return newRarityImgValue
        //     }

        //     return currentState;
        //   })

        //   const NFTCardData = {
        //     id: i,
        //     energy: energy.toNumber(),
        //     level: level.toNumber(),
        //     lucky: lucky.toNumber(),
        //     name,
        //     rarity,
        //   }
        //   addNFT(NFTCardData);
        // }
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (ethereum) {
      getBoxes();
      checkNFTs();
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccount, isMetamaskInstall]);

  useEffect(() => {
    checkIfWalletIsConnected();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccount, ethereum]);

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (contractListened) {
      contractListened.off("Redeemed", redeemedEvent);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractListenedStatus]);

  useEffect(() => {
    if (ethereum) {
      const {
        bearExContract2,
      } = createEthereumContract();

      setContractListened(bearExContract2);
    }
    
  }, [isMetamaskInstall])

  return (
    <NFTContractContext.Provider
      value={{
        currentAccount,
        shortCurrentAccount,
        connectWallet,
        boxes,
        getNFT,
        NFTs,
        NFTCardSideToggle,
        width,
        newNFTCard,
        contractListened,
        setContractListened,
        setBoxes,
        setNewNFTCard,
        addNFT,
        accountImgValue,
        isMetamaskInstall,
      }}
    >
      {children}
    </NFTContractContext.Provider>
  )
}
