import React, { useState, useEffect } from 'react';

import NFTCardCSS from './NFTCard.module.scss';

import cx from 'classnames';
import { NFTCardFront } from '../NFTCardFront/NFTCardFront';
import { NFTCardBack } from '../NFTCardBack/NFTCardBack';
import { back, fileNames, front } from '../../utils/fileNames';
import { useMemo } from 'react';

export const NFTCard = (props) => {
  const {
    smallCardSize,
    card,
    toggle = false,
  } = props;

  const {
    id,
  } = card;

  const imageName = fileNames.find(i => {
      
    return +i.slice(0, i.indexOf('_')) === id;
  })

  const cardNameFront = useMemo(() => {
    
    return `${front}/${imageName}.png`;
  }, [id]);

  const cardNameBack = useMemo(() => {
    return `${back}/${imageName}.png`;
  }, [id]);

  const [ frontSide, setFrontSide ] = useState(false);

  const toggleSide = () => {
    setFrontSide(currentState => !currentState);
  }

  useEffect(() => {
    if (toggle) {
      setFrontSide(true);
    }
  }, [toggle]);

  useEffect(() => {
    console.log(cardNameFront)
  }, [])

  return (
    <div
      // className={cx(NFTCardCSS.NFTCard, {[NFTCardCSS.NFTCard__active]: frontSide})}
      className={cx({
        [NFTCardCSS.NFTCard]: smallCardSize !== 'small',
        [NFTCardCSS.NFTSmallCard]: smallCardSize === 'small',
        [NFTCardCSS.NFTCard__active]: frontSide,
      })}
      onClick={toggleSide}
    >
      <div
        // className={cx(NFTCardCSS.NFTCard__side, NFTCardCSS.NFTCard__side__front)}
        className={cx({
          [NFTCardCSS.NFTCard__side]: smallCardSize !== 'small',
          [NFTCardCSS.NFTCard__side__front]: smallCardSize !== 'small',
          [NFTCardCSS.NFTSmallCard__side]: smallCardSize === 'small',
          [NFTCardCSS.NFTSmallCard__side__front]: smallCardSize === 'small',
        })}
      >
        <img
          className={NFTCardCSS.NFTCard__front}
          src={cardNameFront}
          alt="front"
        />
      </div>
      <div
        className={cx({
          [NFTCardCSS.NFTCard__side]: smallCardSize !== 'small',
          [NFTCardCSS.NFTCard__side__back]: smallCardSize !== 'small',
          [NFTCardCSS.NFTSmallCard__side]: smallCardSize === 'small',
          [NFTCardCSS.NFTSmallCard__side__back]: smallCardSize === 'small',
        })}
        // className={cx(NFTCardCSS.NFTCard__side, NFTCardCSS.NFTCard__side__back)}
      >
        <img
          className={NFTCardCSS.NFTCard__back}
          src={cardNameBack}
          alt="back"
        />
      </div>
    </div>
  )
}
