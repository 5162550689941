import React from 'react';

import LogoCSS from './Logo.module.scss';

import LogoImg from '../../image/logo.png';

export const Logo = () => {
  return (
    <div
      className={LogoCSS.wrapper}
    >
      <img
        className={LogoCSS.logo}
        alt='logo'
        src={LogoImg}
      />
    </div>
  )
}
