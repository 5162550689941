import React from 'react';

import HeaderButtonsCSS from './HeaderButtons.module.scss';

export const HeaderButtons = () => {
  return (
    <div
      className={HeaderButtonsCSS.headerButtons}
    >
      <a 
        href="https://discord.com/" 
        target="_blank"
        rel="noopener noreferrer"
        className={HeaderButtonsCSS.headerButtons__discordWrapper}
      >
        <div
          className={HeaderButtonsCSS.headerButtons__discord}
        >
          Discord
        </div>
      </a>
      <a
        href="https://google.com/" 
        target="_blank"
        rel="noopener noreferrer"
        className={HeaderButtonsCSS.headerButtons__lightPaperWrapper}
      >
        <div
          className={HeaderButtonsCSS.headerButtons__lightPaper}
        >
          Light paper
        </div>
      </a>
    </div>
  )
}
