import React, {
  useState,
  useEffect,
  useContext,
} from 'react';
import ReactDom from 'react-dom';
import ModalStyle from './Modal.module.scss';
import VideoNft from '../../video/video.mp4';

import cx from 'classnames';

// import Bear from '../../image/NFT/bear.png';
// import FrontImg from '../../image/NFT/front_img.png';
// import LavaBtn from '../../image/NFT/lavabtn.png';
// import OtherBtn from '../../image/NFT/otherbtn.png';
// import Save from '../../image/NFT/save.png';
// import Sets from '../../image/NFT/sets.png';
// import { Header } from '../Header/Header';
import { OutlineBtn } from '../OutlineBtn/OutlineBtn';
import { ModalContext } from '../../context/ModalContext';
// import LogoBearSmall from '../../image/NFT/logo__bear_small.png';

import LogoImg from '../../image/logo.png';
import { NFTContractContext } from '../../context/NFTContractContext';
// import NoPhoto from '../../image/noPhoto.png';

import { NFTCard } from '../NFTCard/NFTCard';

function Modal() {
  const [ openVideo, setOpenVideo ] = useState(true);
  const [ backSide, setBackSide ] = useState(false);
  const [ cardScale, setCardScale ] = useState(false);
  const [ headerAppeared, setHeaderAppeared ] = useState(false);
  const [ cardDisappears, setCardDisappears ] = useState(false);

  const {
    toggleModal,
  } = useContext(ModalContext);

  const {
    newNFTCard,
    contractListened,
    setContractListened,
  } = useContext(NFTContractContext);

  const videoStop = () => {
    setTimeout(() => {
      setOpenVideo(false);
      cardScale1();
    }, 5000);
  }

  const cardScale1 = () => {
    setTimeout(() => {
      setCardScale(true);
      sideChange()
    }, 100);
  }

  const sideChange = () => {
    setTimeout(() => {
      setBackSide(true);
      continueButtonAppeared();
    }, 400);
  }

  const continueButtonAppeared = () => {
    setTimeout(() => {
      setHeaderAppeared(true);
    }, 1000);
  }

  const modalAnimations = () => {
    videoStop();
  }

  const continueBtn = () => {
    document.body.style.overflow = "auto";
    setCardDisappears(true);
    contractListened.removeAllListeners('Redeemed');
    // setContractListened(null)
    setTimeout(() => {
      toggleModal();
    }, 1000);
  }

  useEffect(() => {
    modalAnimations();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return ReactDom.createPortal(  
    <div
      className={ModalStyle.modal}
    >
      {openVideo 
        && (
          <video
            className={ModalStyle.video}
            autoPlay 
            muted
            src={VideoNft}
          >

          </video>
        )
      }
      <div
        className={cx(ModalStyle.modal__bg, {[ModalStyle.modal__bg_active]: !openVideo})}
      >
        <div
          className={cx(ModalStyle.modal__header, {[ModalStyle.modal__header_active]: headerAppeared})}
        >
          <div
            className={ModalStyle.modal__logo}
          >
            <img 
              src={LogoImg}
              alt='logo'
            />
          </div>
        </div>
        <div
          className={cx(ModalStyle.modal__NFTCard, {[ModalStyle.modal__NFTCard_active]: cardScale})}
        >
          <div
            className={cx(ModalStyle.card,
              {
                [ModalStyle.card__disappears]: cardDisappears,
              }
            )}
          >
            <NFTCard 
              smallCardSize='small'
              card={newNFTCard}
              toggle={backSide}
            />
          </div>
        </div>
        <div
          className={cx(ModalStyle.modal__continueBtn, {[ModalStyle.modal__continueBtn_active]: headerAppeared})}
        >
          <OutlineBtn
            title='Continue'
            action={continueBtn}
          />
        </div>
      </div>
    </div>, 
    document.getElementById('nftVideo')
  );
}

export default Modal;