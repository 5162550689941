import ConnectWalletBtnCSS from './ConnectWalletBtn.module.scss';

import CardsGroup from '../../image/cardsGroup.png';

function ConnectWalletBtn(props) {
  const { action } = props;
  return (
    <div
      className={ConnectWalletBtnCSS.buttonBlock}
    >
      <div
        className={ConnectWalletBtnCSS.cardsGroup}
      >
        <img 
          src={CardsGroup}
          alt='cards'
        />
      </div>
      <div onClick={action} className={ConnectWalletBtnCSS.button}>
        <div className={ConnectWalletBtnCSS.button__inner}>Connect wallet</div>
      </div>
    </div>
    
  );
}

export default ConnectWalletBtn;