export const zeroBoxes = [
  {
    id: 1,
    boxId: 1,
    buttonTitle: 'buy',
  },
  {
    id: 2,
    boxId: 2,
    buttonTitle: 'buy',
  },
  {
    id: 3,
    boxId: 3,
    buttonTitle: 'buy',
  },
  {
    id: 4,
    boxId: 1,
    buttonTitle: 'buy',
  },
  {
    id: 5,
    boxId: 2,
    buttonTitle: 'buy',
  },
  {
    id: 6,
    boxId: 3,
    buttonTitle: 'buy',
  },
];

export const oneBox = [
  {
    id: 2,
    boxId: 1,
    buttonTitle: 'buy',
  },
  {
    id: 3,
    boxId: 2,
    buttonTitle: 'buy',
  },
  {
    id: 4,
    boxId: 3,
    buttonTitle: 'buy',
  },
  {
    id: 5,
    boxId: 1,
    buttonTitle: 'buy',
  },
  {
    id: 6,
    boxId: 2,
    buttonTitle: 'buy',
  },
]

export const twoBoxes = [
  {
    id: 3,
    boxId: 1,
    buttonTitle: 'buy',
  },
  {
    id: 4,
    boxId: 2,
    buttonTitle: 'buy',
  },
  {
    id: 5,
    boxId: 3,
    buttonTitle: 'buy',
  },
  {
    id: 6,
    boxId: 1,
    buttonTitle: 'buy',
  },
]

export const threeBoxes = [
  {
    id: 4,
    boxId: 1,
    buttonTitle: 'buy',
  },
  {
    id: 5,
    boxId: 2,
    buttonTitle: 'buy',
  },
  {
    id: 6,
    boxId: 3,
    buttonTitle: 'buy',
  },
]
