import React, { useContext, useMemo } from 'react';

import BoxesToOpenCSS from './BoxesToOpen.module.scss';

import Card1 from '../../image/card1.png';
import Card2 from '../../image/card2.png';
import Card3 from '../../image/card3.png';
import {
  zeroBoxes,
  oneBox,
  twoBoxes,
  threeBoxes,
} from '../../utils/dataForBoxes';
import { NFTContractContext } from '../../context/NFTContractContext';

export const BoxesToOpen = () => {
  const {
    boxes,
    getNFT,
    currentAccount,
  } = useContext(NFTContractContext);

  const boxesToOpen = useMemo(() => {
    if (boxes.length === 0) {
      return [
        ...zeroBoxes,
      ]
    }

    if (boxes.length === 1) {
      return [
        ...boxes,
        ...oneBox,
      ]
    }

    if (boxes.length === 2) {
      return [
        ...boxes,
        ...twoBoxes,
      ]
    }

    if (boxes.length === 3) {
      return [
        ...boxes,
        ...threeBoxes,
      ]
    }

    if (boxes.length % 3 === 1) {
      return [
        ...boxes,
        {
          id: boxes.length + 1,
          boxId: 1,
          buttonTitle: 'buy',
        },
        {
          id: boxes.length + 2,
          boxId: 2,
          buttonTitle: 'buy',
        },
      ]
    }

    if (boxes.length % 3 === 2) {
      return [
        ...boxes,
        {
          id: boxes.length + 1,
          boxId: 1,
          buttonTitle: 'buy',
        }
      ]
    }

    return [ ...boxes ];
  }, [boxes])

  return (
    <div className={BoxesToOpenCSS.container}>
      <div
        className={BoxesToOpenCSS.main}
      >
        <div className={BoxesToOpenCSS.main__title}>
          <div>
            Boxes to open
          </div>
        </div>
        <div className={BoxesToOpenCSS.main__wrapper}>
          {
            currentAccount
                ? (
                  <>
                    {boxesToOpen.map((box, i) => {
                      const {
                        id,
                        boxId,
                        buttonTitle,
                      } = box;

                      return (
                        <div
                          key={i}
                          className={BoxesToOpenCSS.main__block}
                          onClick={() => {
                            // boxSelected(id);
                            if (buttonTitle === 'open') {
                              getNFT(boxId);
                            }
                          }}
                        >
                          <div className={BoxesToOpenCSS.main__block_number}>
                            #0{id}
                          </div>
                          <img src={boxId === 1 ? Card1 : boxId === 2 ? Card2 : Card3} alt={BoxesToOpenCSS.block} />
                          <div className={BoxesToOpenCSS.main__block_open}>
                            {buttonTitle === 'buy' 
                              ? (
                                <a
                                  href='https://opensea.io/'
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {buttonTitle}
                                </a>
                              ) 
                              : (
                                <div>
                                  {buttonTitle}
                                </div>
                              )
                            }
                          </div>
                        </div>
                      )
                    })}
                  </>
                )
                : (
                  <div
                    className={BoxesToOpenCSS.main__warning}
                  >
                    To see your boxes connect your Metamask wallet
                  </div>
                )
              }
        </div>
      </div>
    </div>
  )
}
