import React from 'react';

import SocialCSS from './Social.module.scss';

import InstagramImage from '../../image/inst.png';
import TelegramImage from '../../image/telegram.png';
import TwitterImage from '../../image/twitter.png';
import DiscordImage from '../../image/discord.png';

const socialData = [
  {
    id: 1,
    name: 'instagram',
    href: 'https://www.instagram.com/',
    image: InstagramImage,
  },
  {
    id: 2,
    name: 'telegram',
    href: 'https://web.telegram.org/',
    image: TelegramImage,
  },
  {
    id: 3,
    name: 'twitter',
    href: 'https://twitter.com/',
    image: TwitterImage,
  },
  {
    id: 4,
    name: 'discord',
    href: 'https://discord.com/',
    image: DiscordImage,
  },
]

export const Social = () => {
  return (
    <div
      className={SocialCSS.social}
    >
      {socialData.map(chain => {
        const {
          id,
          name,
          href,
          image,
        } = chain;

        return (
          <a
            className={SocialCSS.social__link}
            href={href}
            key={id}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className={SocialCSS.social__img}
              src={image}
              alt={name}
            />
          </a>
        )
      })}
    </div>
  )
}
