import React, {
  useContext,
}  from 'react';

import HeaderCSS from './Header.module.scss';

import { Logo } from '../Logo/Logo';
import { AccountInfo } from '../AccountInfo/AccountInfo';
import { HeaderButtons } from '../HeaderButtons/HeaderButtons';
import { NFTContractContext } from '../../context/NFTContractContext';

export const Header = () => {
  const {
    currentAccount,
    width,
  } = useContext(NFTContractContext);

  return (
    <div
      className={HeaderCSS.header}
    >
      <div
        className={HeaderCSS.header__logo}
      >
        <Logo />
      </div>
      <div
        className={HeaderCSS.header__accountInfo}
      >
        {currentAccount 
          && (
            <AccountInfo />
          ) 
        }
      </div>
      {(width > 991 
        ? (
          <div
            className={HeaderCSS.header__buttons}
          >
            <HeaderButtons />
          </div>
        )
        : !currentAccount 
          && (
            <div
              className={HeaderCSS.header__buttons}
            >
              <HeaderButtons />
            </div>
          )
      )}
    </div>
  )
}
