import React from 'react';
import { useContext } from 'react';
import ReactDom from 'react-dom';
import { ModalContext } from '../../context/ModalContext';
import ErrorOpenNFTModalCSS from './ErrorOpenNFTModal.module.scss';

import RedEyes from '../../image/Red_Eyes.png';
import CloseButtonImg from '../../image/CloseButton.svg';

export const ErrorOpenNFTModal = () => {
  const {
    toggleNFTErrorModal,
  } = useContext(ModalContext);
  return ReactDom.createPortal(
    <div
      className={ErrorOpenNFTModalCSS.errorModal}
    >
      <div
        className={ErrorOpenNFTModalCSS.errorModal__border}
      >
        <div
          className={ErrorOpenNFTModalCSS.errorModal__bg}
        >
          <div
            className={ErrorOpenNFTModalCSS.errorModal__badge}
          >
            <div
              className={ErrorOpenNFTModalCSS.errorModal__badge_bg}
            >
              <img
                src={RedEyes}
                alt="Red Eyes"
              />
            </div>
          </div>
          <div
            className={ErrorOpenNFTModalCSS.errorModal__title}
          >
            Box wasn't opened, something going wrong.
          </div>
          <button
            className={ErrorOpenNFTModalCSS.errorModal__button}
            onClick={toggleNFTErrorModal}
          >
            <div
              className={ErrorOpenNFTModalCSS.errorModal__buttonTitle}
            >
              Back
            </div>
          </button>
          <div
            className={ErrorOpenNFTModalCSS.errorModal__closeButton}
          >
            <img
              src={CloseButtonImg}
              alt="close"
            />
          </div>
        </div>
      </div>
    </div>,
    document.getElementById('errorOpenNFTModal')
  );
}
