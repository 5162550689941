// import BearEx2 from './BearExNFT2.json';
import BearEx2 from './abi.json';
// import BearExBoxes from './BearExBoxes.json';
import BearExBoxes from './abiBox.json';

export const bearExAbi2 = BearEx2;
export const bearExAddress2 = '0x37b282743647261Fcd5BBc3e2A792D3cd8fC1cE1';

export const bearExBoxesAbi = BearExBoxes;
export const bearExBoxesAddress = '0x9C740003D015bc0b86C3eEf171a50b771F44C456';
