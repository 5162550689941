import React from 'react';
import { createPortal } from 'react-dom';

import LoadingModalCSS from './LoadingModal.module.scss';

// import LoadImg from '../../image/loadImg.png';

import LoadingVideo from '../../video/loadingVideo.mp4';

import LoadingDesktopBgVideo from '../../video/bg.mp4';

import LoadingTitle from '../../video/text.gif';
import Bear from '../../video/bear.gif';

// import {
//   buildStyles,
//   CircularProgressbarWithChildren,
// } from 'react-circular-progressbar';
// import 'react-circular-progressbar/dist/styles.css';
// import ChangingProgressProvider from '../../context/ChangingProgressProvider';

export const LoadingModal = () => {
  return createPortal(
    <div
      className={LoadingModalCSS.loadingModal}
    >
      <video
        className={LoadingModalCSS.video}
        autoPlay 
        muted
        loop
        src={LoadingDesktopBgVideo}
      >
      </video>

      <div
        className={LoadingModalCSS.loadingModal__bear}
      >
        <img src={Bear} alt="bear"/>
      </div>

      <div
        className={LoadingModalCSS.loadingModal__title}
      >
        <img src={LoadingTitle} alt="loading"/>
      </div>



      {/* <video
        className={LoadingModalCSS.video}
        autoPlay 
        muted
        loop
        src={LoadingVideo}
      >

      </video>
      <div
        className={LoadingModalCSS.loadingModal__title}
      >
        Loading your NFT
      </div> */}


      {/* <div style={{ width: 300, height: 300, marginBottom: '36px', }}>
        <ChangingProgressProvider
          values={[0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100]}
        >
          {percentage => {
            return (
              <CircularProgressbarWithChildren
                value={percentage}
                text={`${percentage}%`}
                styles={buildStyles({
                  pathColor: `#905CFF`,
                })}
              >
              <img
                className={LoadingModalCSS.loadingModal__img}
                src={LoadImg}
                alt='loadImg'
              />
              </CircularProgressbarWithChildren>
            )
          }}
        </ChangingProgressProvider>
      </div> */}
    </div>,
    document.getElementById('loadingModal')
  )
}
