import React, { useContext } from 'react';

import AccountInfoCSS from './AccountInfo.module.scss';

import NoPhoto from '../../image/noPhoto.png';
import LoadImg from '../../image/loadImg.png';
import { NFTContractContext } from '../../context/NFTContractContext';

const accountImgs = [
  NoPhoto,
  LoadImg,
  LoadImg,
  LoadImg,
  LoadImg,
  LoadImg,
  LoadImg,
  LoadImg,
  LoadImg,
  LoadImg,
]

export const AccountInfo = () => {  
  const {
    shortCurrentAccount,
    accountImgValue,
  } = useContext(NFTContractContext);

  return (
    <div
      className={AccountInfoCSS.accountInfo}
    >
      <img
        className={AccountInfoCSS.accountInfo__img}
        src={accountImgs[accountImgValue]}
        alt='accountPhoto'
      />
      <div
        className={AccountInfoCSS.accountInfo__name}
      >
        {`Account ${shortCurrentAccount}`}
      </div>
    </div>
  )
}
