import React from 'react';
import { createPortal } from 'react-dom';

import MetamaskModalCSS from './MetamaskModal.module.scss';

export const MetamaskModal = () => {
  return createPortal(
    <div
      className={MetamaskModalCSS.modal}
    >
      <div
        className={MetamaskModalCSS.modal__bg}
      >
        <a
          className={MetamaskModalCSS.modal__link}
          href='https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=uk'
          target="_blank"
          rel="noopener noreferrer"
        >
          Install MetamaskModal extension
        </a> 
      </div>
    </div>,
    document.getElementById("metamaskModal")
  )
}
