import React from 'react';

import OutlineBtnCSS from './OutlineBtn.module.scss';

export const OutlineBtn = (props) => {
  const {
    title='button',
    action= () => {},
  } = props;
  return (
    <div
      className={OutlineBtnCSS.outlineBtnWrapper}
      onClick={action}
    >
      <div
        className={OutlineBtnCSS.outlineBtn}
      >
        {title}
      </div>
    </div>
  )
}
