import React, { useContext } from 'react';

import FooterCSS from './Footer.module.scss';

import { Social } from '../Social/Social';
import { NFTContractContext } from '../../context/NFTContractContext';

export const Footer = () => {
  const {
    width,
  } = useContext(NFTContractContext);

  return (
    <div
      className={FooterCSS.main}
    >
      <div
        className={FooterCSS.main__title}
      > 
        Follow our socials!
      </div>
      <div
        className={FooterCSS.main__social}
      >
        <Social />
      </div>
      {width > 576 
        && (
          <a
            className={FooterCSS.main__subtitle}
            href="https://google.com/" 
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy policy
          </a>
        )
      }
    </div>
  )
}
