import React, {
  // useState,
  useContext,
}  from 'react';
import MainCSS from './Main.module.scss';
import cx from 'classnames';
import { NFTContractContext } from '../../context/NFTContractContext';

import { NFTCard } from '../NFTCard/NFTCard';

export const Main = () => {
  const {
    NFTs,
    // NFTCardSideToggle,
    width,
    currentAccount,
  } = useContext(NFTContractContext);
  return (
    <>
      <div className={MainCSS.border}>
        <div className={MainCSS.section__title}>
          <div>Your NFTs</div>
        </div>
        <div className={cx(MainCSS.wrapper, {[MainCSS.wrapper__odd]: NFTs % 2 === 1})}>
          {(NFTs.length === 0 || !currentAccount) 
            && (
              <div
                className={MainCSS.warning}
              >
                You currently have no NFT
              </div>
            )
          }
          {NFTs.map(NFT => {
            const {
              id,
            } = NFT;
  
            return (
              <div
                className={cx({
                  [MainCSS.card]: width >= 1300,
                  [MainCSS.smallCard]: width < 1300,
                })}
                key={id}
              >
                <NFTCard 
                  smallCardSize={width < 1325 ? 'small' : 'large'}
                  card={NFT}
                />
              </div>
            )
          })}
        </div>
      </div>
     
    </>
  )
}
