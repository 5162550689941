import React, {
  useContext,
} from 'react';
import AppCSS from './App.module.scss';
import { Header } from './components/Header/Header';
import { Main } from './components/Main/Main';
import { Footer } from './components/Footer/Footer';
import { BoxesToOpen } from './components/BoxesToOpen/BoxesToOpen';
import Modal from './components/Modal/Modal';
import { ModalContext } from './context/ModalContext';
import { NFTContractContext } from './context/NFTContractContext';
import ConnectWalletBtn from './components/ConnectWalletBtn/ConnectWalletBtn';
import { LoadingModal } from './components/LoadingModal/LoadingModal';
import { MetamaskModal } from './components/MetamaskModal/MetamaskModal';
import { ErrorOpenNFTModal } from './components/ErrorOpenNFTModal/ErrorOpenNFTModal';

function App() {
  const {
    modalIsOpen,
    loadingModalIsOpen,
    loadingNFTErrorModalIsOpen,
  } = useContext(ModalContext);

  const {
    currentAccount,
    connectWallet,
    NFTs,
    isMetamaskInstall,
  } = useContext(NFTContractContext);

  return (
    <div
      className={AppCSS.wrapper}
    >
      {!isMetamaskInstall && <MetamaskModal />}
      {modalIsOpen && <Modal />}
      {loadingModalIsOpen && <LoadingModal />}
      {loadingNFTErrorModalIsOpen && <ErrorOpenNFTModal />}
      <div className={AppCSS.App}>
        <div className={AppCSS.App__header_wrapper}>
          <Header />
          {currentAccount && <BoxesToOpen />}
          {!currentAccount && 
            <div className={AppCSS.App__section_btn}>
              <ConnectWalletBtn action={connectWallet} />
            </div>
          }
        </div>
        {NFTs.length > 0 && <Main />}
        <Footer />
      </div>
    </div>
  );
}

export default App;
