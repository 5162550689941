import React, {
  useState,
} from 'react';

export const ModalContext = React.createContext();

export const toggle = currentState => !currentState;

export const ModalProvider = ({ children }) => {
  const [ modalIsOpen, setModalIsOpen ] = useState(false);
  const [ loadingModalIsOpen, setLoadingModalIsOpen ] = useState(false);
  const [ loadingNFTErrorModalIsOpen, setLoadingNFTErrorModalIsOpen ] = useState(false);

  const toggleModal = () => {
    if (!modalIsOpen) {
      document.body.style.overflow = "hidden";
    }
    setModalIsOpen(toggle);
  };

  // const toggleLoadingModal = () => {
  //   setLoadingModalIsOpen(toggle);
  // }

  const openLoadingModal = () => {
    setLoadingModalIsOpen(true);
  }

  const closeLoadingModal = () => {
    setLoadingModalIsOpen(false);
  }

  const toggleNFTErrorModal = () => {
    setLoadingNFTErrorModalIsOpen(toggle);
  }

  return (
    <ModalContext.Provider
      value={{
        modalIsOpen,
        toggleModal,
        loadingModalIsOpen,
        openLoadingModal,
        closeLoadingModal,
        loadingNFTErrorModalIsOpen,
        toggleNFTErrorModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  )
}
